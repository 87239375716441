import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Link
} from 'react-browser-router';
import GenerateSong from './view/generate-song';
import GenerateAlbum from './view/generate-album';
import Nav from './component/nav';
import Home from './view/home';
import Song from './view/song';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <div>
            <Nav />
            <Route exact path="/" component={Home} />
            <Route path="/song/:songData" component={Song} />
            <Route path="/generate-song" component={GenerateSong} />
            <Route path="/generate-album" component={GenerateAlbum} />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
