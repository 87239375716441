import React, { Component } from 'react';

class GenerateAlbum extends Component {
  render() {
    return (
      <h2>album</h2>
    );
  }
}

export default GenerateAlbum;
