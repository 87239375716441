import React, { Component } from 'react';
import Lyrics from '../../component/lyrics';
import Beat from '../../component/beat';
import "./style.css";

class Song extends Component {
  state = {
    song: {lines: []},
    album: null,
  }

  componentDidMount() {
    const songData = window.location.pathname.replace("/song/", "");
    const songJson = window.atob(songData);
    const song = JSON.parse(songJson);

    console.log(song);

    this.setState({song});
  }

  renderBeat() {
    if(this.state.song.album)
      return (<Beat album={this.state.song.album} />);
    else
      return (null);
  }

  render() {
    return (
      <div className="song-container">
        <Lyrics 
          song={this.state.song} />
        <div className="song-beat-container">
          { this.renderBeat() }
        </div>
        <div className="song-footer">
          
        </div>
      </div>
    );
  }
}

export default Song;
