import React, { Component } from 'react';
import LifeArt from "./art/life.jpg";
import NwtsArt from './art/nwts.png'
import ScorpionArt from "./art/scorpion.jpg";
import TcArt from "./art/tc.jpg";
import TmlArt from "./art/tml.jpg";
import TooLateArt from "./art/toolate.png";
import ViewsArt from "./art/views.jpg";
import "./style.css";


class Art extends Component {
  renderArt() {
    switch(this.props.album) {
      case "TML":
        return (<img className="art" src={TmlArt} />);
      case "TC":
        return (<img className="art" src={TcArt} />);
      case "NWTS":
        return (<img className="art" src={NwtsArt} />);
      case "Views":
        return (<img className="art" src={ViewsArt} />);
      case "Scorpion":
        return (<img className="art" src={ScorpionArt} />);
      case "Life":
        return (<img className="art" src={LifeArt} />);
      case "TooLate":
        return (<img className="art" src={TooLateArt} />);
      default:
        return (null);
    }
  }

  render() {
    console.log("rendering album for art " + this.props.album)
    return this.renderArt();
  }
}

export default Art;
