import React, { Component } from 'react';
import { Link } from 'react-browser-router';
import "./style.css";

class Home extends Component {
  render() {
    return (
      <div className="home-container">
        <div className="home-inner">
          <h1 className="home-title">Drake🤖</h1>
          <p className="home-description">
            Drake has been accused of using a ghost writer for several years. However, it wasn't a ghost -- it was a robot, made by <strong>Gabriel Akdemir</strong> and <strong>Brandon Bielsa</strong> 
          </p>
          <p className="home-description">
            <ul className="home-best-list">
              <li className="home-best"><a href="http://drizzybot.net:3000/song/eyJ0aXRsZSI6IkppbmdsZSIsImxpbmVzIjpbIkhPT0siLCJsb3ZlIHRvIHRhbGsgTWUgSSBtIGp1c3QgZG9uZSIsImNlbGwgcGhvbmUgTGF0ZSBuaWdodCB3aGVuIHlvdSBBdyBwbGVhc2UgZ2l2ZSBtZSIsImluIHRoZSBjaXR5IHN0aWxsIEkgbSBzdGlsbCBoZXJlIGRvZyIsIlZFUlNFIiwiSSBsbCB0YWtlIHVzIHdoZXJldmVyIEkgbSB5b3VuZyBtb25leSIsInNsb3dpbiBtZSB3aGVuIHRoZSBraWQgaGFkIHdhdmVzIEJ1dCB0aGF0IiwidG8gdGVhY2ggcGVvcGxlIHRoZSBsZXNzb24gRmVlbCBsaWtlIHdlIGdvIGJhY2siLCJmb3Igd29tZW4gdGhhdCBmb3JjZSBtZSB0byBnaXZlIHlvdSBiYWNrIEFsbCIsIm1lYW4geW91IHlvdSB5b3UgdGhpbmtpbiBvZiIsIml0IHdpdGggYSBjb252ZXJzYXRpb24gWW91ciBiZXN0IGRheSBpcyBteSBvbmx5IiwiY29udmVyc2F0aW9uIGhpc3RvcnkgeW91IGtub3cgSSBtIHNvIGluZGVjaXNpdmUgWWVhaCIsIlJpZ2h0IG15IGNvbmZpZGVuY2UgbGV2ZWwgZ2V0dGluIHN0cmFpZ2h0IHRvIHRoZSIsInVwIHRoZSBzaWxlbmNlIFlvdSBrbm93IGF0IHRoZSBob3VzZSIsIm5vdCBpbiBhIHpvbmUgeWVhaCBJIG5lZWQgQW5kIG9uIiwiQ2hhbmdpbmcgeW91ciBvcGluaW9uIG9uIG1lIEJ1dCBJIG5ldmVyIHNheSBJIiwid2l0aG91dCB0aGUgdGVuZGVyIGxvdmUgYW5kIEkgZmVlbCBsaWtlIiwicHJheSB0byBtYWtlIGVtIEdpcmwgSSBtIHJlYWxpemluZyIsIm1lIHlvdSBsb3ZlZCBtZSB0aGF0IHMgaW4gbXkgY2xvc2V0IiwibGluZSBCdXN5IGFzIHVzdWFsIGJ1dCB3ZSBzaG91bGQgbWFrZSBzb21lIHRpbWUiLCJIT09LIiwibG92ZSB0byB0YWxrIE1lIEkgbSBqdXN0IGRvbmUiLCJjZWxsIHBob25lIExhdGUgbmlnaHQgd2hlbiB5b3UgQXcgcGxlYXNlIGdpdmUgbWUiLCJpbiB0aGUgY2l0eSBzdGlsbCBJIG0gc3RpbGwgaGVyZSBkb2ciXSwiYWxidW0iOiJWaWV3cyJ9">Jingle</a></li>
              <li className="home-best"><a href="http://drizzybot.net:3000/song/eyJ0aXRsZSI6IjUgQU0gaW4gR3VpbmVhIiwibGluZXMiOlsiSE9PSyIsImp1c3QgcmVhbGl6ZWQgd2hhdCBpdCBpcyB3aGVuIEkgbSBkb2luZyBtZSIsInBlb3BsZSBJIGRvbiB0IGhhdmUgV29uIHQgZXZlbiBnaXZlIG1lIiwiYmFkZGVzdCBnaXJsIGFyb3VuZCByb3VuZCByb3VuZCBhbmQgdGhleSBub3RpY2UgdGhleSBub3RpY2UiLCJWRVJTRSIsIndlIGJlIGJsb3dpbiBhbmQgeW91IGNhbiB0cnkgYW5kIiwicyBwcm9iYWJseSB0cnVlIEN1eiB3aGlsZSBhbGwgb2YgbXkgY2xvc2VzdCBmcmllbmRzIiwiY291cGxlIHNoZWxscyBhbmQgc2hvb3QgVGhpcyBzaGl0IGZlZWwgbGlrZSB3aGVuIHNoZSIsImxpdmluZyBvdXQgYSBkcmVhbSBpdCBUaGUgb25lIHRoYXQiLCJkYXlzIGFuZCB3ZW50IHdpdGggU3ByaXRlIGluc3RlYWQgVWggaHVoIiwiYWx3YXlzIGZlZWwgbGlrZSB3aGVuIEZyZWRybyBTdGFyciB3YXMgYXQgU3Vuc2V0IFBhcmsiLCJ1cCBhIGJsdW50IHdpdGggbXkgaGVhcnQgYmVhdGluZyBpbiBkb3VibGUgdGltZSIsIm15IGZyaWVuZHMgZGlmZmVyZW50IE1heWJlIGNhdXNlIHRoZSBnaXJsIEkgdGhvdWdodCIsInQgdGhhdCBzZXJpb3VzIFRoZXJlIHdhcyBzbW9rZSBpbiB0aGUgY2l0eSB0cnlpbmciLCJpcyBqdXN0IG5vdCBhcyBmdW4gTmVrcyBnb3QgdGhlIHJlc29sdXRpb25zIiwid291bGQgaGF2ZSB5b3UgaW4gbXkgZXllcyByaWdodCBub3cgYW5kIiwid2VudCB3aXRoIFNwcml0ZSBpbnN0ZWFkIFVoIGh1aCB0aGF0IHMiLCJ3ZSBqdXN0IGhhdmluZyB0aGUgdGltZSBvZiBvdXIgbGl2ZXMgcmlnaHQgbm93IiwicyBhbHdheXMgcm9sbGluIFNvIGNvbWUgYW5kIGdldCBhIHBvcnRpb24iLCJtIGxheWluZyBuZXh0IHRvIGp1c3QgbG9va2VkIG92ZXIgYW5kIHJlYWQgaXQiLCJIT09LIiwianVzdCByZWFsaXplZCB3aGF0IGl0IGlzIHdoZW4gSSBtIGRvaW5nIG1lIiwicGVvcGxlIEkgZG9uIHQgaGF2ZSBXb24gdCBldmVuIGdpdmUgbWUiLCJiYWRkZXN0IGdpcmwgYXJvdW5kIHJvdW5kIHJvdW5kIGFuZCB0aGV5IG5vdGljZSB0aGV5IG5vdGljZSJdLCJhbGJ1bSI6IlRNTCJ9">5 AM in Guinea</a></li>
              <li className="home-best"><a href="http://drizzybot.net:3000/song/eyJ0aXRsZSI6IjEwIEFNIGluIFdlc3QgVmlyZ2luaWEiLCJsaW5lcyI6WyJIT09LIiwibm8gbm8gbm8gWW91IG5lZWQgbWUgdG8gSSIsInNlZWluIHNvIHNob3V0IGdvZXMgb3V0IHRvIERpZCBJIGxvc2UiLCJtYW4gdHVybiBmb29sIGZvciB0aGUgbW9uZXkgT25lIHRvbyBtYW55IHRpbWVzIiwiVkVSU0UiLCJmb3Igbm90aGluIGFuZCByaXZhbCBuZWlnaGJvcmhvb2RzIGxpbmtpbiBBbmQgcHV0dGluIiwicG9zdCBJIHN0YXJ0IG15IGRheSBzbG93IFNpbGsgcGFqYW1hcyB3aGVuIEkiLCJkb3VibGUgY2xpY2sgTW9yZSBibGVzc2luZ3MgYmVjYXVzZSBJIG0gbG9va2luIGV4aGF1c3RlZCIsIm5ldmVyIHBsYWNpbiBzZWNvbmQgQW5kIGV2ZW4gYmV0dGVyIGtub3dpbiB5b3UiLCJZb3UgbmlnZ2FzIG1vdmUgbGlrZSB0aGUgb25lIHR3byB5ZWFoIFlvdSIsIlRyeW5hIGNvb2wgaXQgYWxsIGp1c3Qgc3dpdGNoZWQgb3ZlciB0aW1lIiwiYWZ0ZXIgeW91IGNvdW50IHRoYXQgTWUgYW5kIG15IGJyb3NraSBhcmUgdHdpbnMiLCJ5b3VyIG5leHQgYWxidW0gcHJvYmFibHkgd29uIHQgZXZlciBzZWUgdGhlIHJlYWwiLCJjb2xsZWdlIHNob3dzIENhbGlwYXJpIGZsb3cgVGhlbiBJIHBvcHBlZCBsaWtlIiwiTm93IG1lIGFuZCBDaGVmIEtEIEJldCBvbiBzaG90cyBmb3IiLCJ3ZSBhcmUgWW91ciBmcmllbmRzIGFyZSBzY2FyZWQgdG8gdGVsbCB5b3UgeW91IiwiZmVlbCB0aGUgdGVuc2lvbiBhbmQgdGhlIHVyZ2VuY3kgTGFzdCBjaGFuY2UgSSBnZXQiLCJyZW1peCB0byBvciBzb21ldGhpbiBOb3Qgd2hlbiBldmVyeWJvZHkgaXMiLCJnbyBUaGV5IGNvdWxkIHNlZSBtZSBpbiBhbiBlbWVyZ2VuY3kiLCJteSBuYW1lIFdobyBzIGludm9sdmVkIG5vdyB3aHkgd2FpdCIsIkhPT0siLCJubyBubyBubyBZb3UgbmVlZCBtZSB0byBJIiwic2VlaW4gc28gc2hvdXQgZ29lcyBvdXQgdG8gRGlkIEkgbG9zZSIsIm1hbiB0dXJuIGZvb2wgZm9yIHRoZSBtb25leSBPbmUgdG9vIG1hbnkgdGltZXMiXSwiYWxidW0iOiJMaWZlIn0=">10 AM in West Virginia</a></li>
            </ul>
          </p>
        </div>
      </div>
    );
  }
}

export default Home;
