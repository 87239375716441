import React, { Component } from 'react';
import { Link } from 'react-browser-router';
import "./style.css";
import Owl from "./ovoxo-owl.png";

class Nav extends Component {
  render() {
    return (
      <div className="nav-container">
        <div className="nav-container-inner">
          <div className="nav-chevron">

          </div>
          <ul className="nav">
            <li className="nav-item nav-logo"><img src={Owl} /></li>
            <li className="nav-item"><Link to="/">Home</Link></li>
            <li className="nav-item"><Link to="/generate-song">Generate Song</Link></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Nav;
