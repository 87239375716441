import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Lyrics from '../../component/lyrics';
import Art from "../../component/art";
import "./style.css";

class GenerateSong extends Component {
  state = {
    title: null,
    lines: null,
    titleReceived: false,
    selectedAlbum: "TML"
  }

  requestTitle() {
    const rantEndpoint = "http://rantbox.lett.at/run";
    const titlePattern = "[case:title]{{12|1|2|3|4|5|6|7|8|9|10|11} {AM|PM} in {<country>|<state -usa>}|I'm <adj>|<verb>}";
    const formData = new FormData();

    formData.append("pattern", titlePattern);

    return fetch(rantEndpoint, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        const generatedTitle = response.output.main;

        this.setState({
          title: generatedTitle,
          titleReceived: true,
        })

        const albumTitleInput = document.getElementById("song-title-input");

        if(!albumTitleInput)
          return;

        albumTitleInput.value = generatedTitle;
      });
  }

  requestSong(album) {
    const generateEndpoint = "http://107.191.111.195:8090/postjson";
    const body = {
      album,
    }

    console.log(body);
    console.log(album);

    return fetch(generateEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(response => {
        const lines = response.lines;
        const formattedLines = lines
          .map(line => line.replace("\n", " ").trim());

        this.setState({lines: formattedLines});
      });
  }

  encodeSong(title, lines, album) {
    const song = {
      title,
      lines,
      album,
    };
    console.log(song);
    const songJson = JSON.stringify(song);
    console.log(songJson);
    const songEncoded = window.btoa(songJson);

    return songEncoded;
  }

  componentDidMount() {
    this.requestTitle();
  }

  generateSong() {
    const titlePromise = this.requestTitle();
    const lyricsPromise = this.requestSong();

    Promise.all([titlePromise, lyricsPromise])
      .then(() => {

        this.props.history()
      })
  }

  onGenerate(e) {
    const album = this.state.selectedAlbum;

    this.requestSong(album)
      .then(() => {
        const lines = this.state.lines;
        const title = this.state.title;
        const encodedSong = this.encodeSong(title, lines, album);

        this.props.history.push("/song/" + encodedSong);
      })
  }

  onSelectAlbum(e) {
    const select = document.getElementById("song-select");
    const options = select.options;
    const selectedIndex = select.selectedIndex;
    const selectedAlbum = options[selectedIndex].value;

    this.setState({
      selectedAlbum
    });
  }

  renderAlbumSelect() {
    return (
      <select id="song-select" onChange={e => this.onSelectAlbum(e)}>
        <option value="TML">Thank Me Later</option>
        <option value="TC">Take Care</option>
        <option value="NWTS">Nothing Was the Same</option>
        <option value="Views">Views</option>
        <option value="Scorpion">Scorpion</option>
        <option value="Life">More Life</option>
        <option value="TooLate">If You're Reading This It's Too Late</option>
      </select>
    );
  }

  // renderAlbum() {
  //   switch(this.state.selectedAlbum) {
  //     case "TML":
  //       return (<img className="song-art" src={TmlArt} />);
  //     case "TC":
  //       return (<img className="song-art" src={TcArt} />);
  //     case "NWTS":
  //       return (<img className="song-art" src={NwtsArt} />);
  //     case "Views":
  //       return (<img className="song-art" src={ViewsArt} />);
  //     case "Scorpion":
  //       return (<img className="song-art" src={ScorpionArt} />);
  //     case "Life":
  //       return (<img className="song-art" src={LifeArt} />);
  //     case "TooLate":
  //       return (<img className="song-art" src={TooLateArt} />);
  //     default:
  //       return (null);
  //   }
  // }

  render() {
    return (
      <div className="song-container">
        <div className="song-inner">
          <h2>Generate Song</h2>
          <div className="song">
            <Art
              album={this.state.selectedAlbum} />
            <div className="song-fields-container">
              <div className="song-field"><h4>Album</h4> { this.renderAlbumSelect() }</div>
              <div className="song-field"><h4>Title</h4><input id="song-title-input" type="text" /></div>
            </div>
            <div className="song-generate-button-container">
              <button onClick={e => this.onGenerate(e)}>
                Generate
              </button>
            </div>
          </div>
        </div>
        <div className="song-footer">
        </div>
      </div>
    );
  }
}

export default GenerateSong;
