import React, { Component } from 'react';
import LifeBeat from "./beat/Life.mp3";
import NwtsBeat from "./beat/NWTS.mp3";
import ScorpionBeat from "./beat/Scorpion.mp3";
import TcBeat from "./beat/TC.mp3";
import TmlBeat from "./beat/TML.mp3";
import TooLateBeat from "./beat/TooLate.mp3";
import ViewsBeat from "./beat/Views.mp3";
import "./style.css";


class Beat extends Component {
  beatSource(album) {
    switch(album) {
      case "Life":
        return LifeBeat;
      case "NWTS":
        return NwtsBeat;
      case "Scorpion":
        return ScorpionBeat;
      case "TC":
        return TcBeat;
      case "TML":
        return TmlBeat;
      case "TooLate":
        return TooLateBeat;
      case "Views":
        return ViewsBeat;
      default:
        return null;
    }
  }

  render() {
    const beatSrc = this.beatSource(this.props.album);

    return (
      <audio className="beat" controls>
        <source src={beatSrc} type="audio/mp3" />
      </audio>
    );
  }
}

export default Beat;
