import React, { Component } from 'react';
import fck from "f-ck";
import Art from "../art";
import './style.css';



class Lyrics extends Component {
  renderLine(line) {
    return (<li className="lyrics-song-line"><p className="lyrics-song-lyric">{ line }</p></li>);
  }

  songLink(song) {
    const songString = JSON.stringify(song);
    const encodedSong = window.btoa(songString);
    const location = window.location;

    return location.protocol + "//" + location.hostname + ":3000/song/" + encodedSong;
  }

  censorLine(line) {
    const profanities = [
      "fuck", "shit", "fucking", "shitty", "pussy", "nigga", "cunt", "bitch", "bitches", "niggas", "shitting", "Bitch", "Fuck", "Nigga", "Shit", "Niggas", "motherfucker", "motherfucking", "motherfuckin"
    ];
    const words = line.split(" ");
    const censoredWords = words.map(w => {
      console.log(w)
      if(profanities.includes(w.toLowerCase()))
        return fck.inner(w)
      else
        return w
    });
    const censoredLine = censoredWords.join(" ");

    return censoredLine;
  }

  shareInputOnClick(e) {

  }

  render() {
    return (
      <div className="lyrics-container">
        <div className="lyrics-header-container">
          <div className="lyrics-header">
            <div className="lyrics-header-inner">
              <div className="lyrics-album-art-container">
                <Art
                  album={this.props.song.album} />
              </div>
              <div className="lyrics-song-info-container">
                <ul className="lyrics-song-info">
                  <li className="lyrics-song-title-container"><h1 className="lyrics-song-title">{ this.props.song.title }</h1></li>
                  <li className="lyrics-song-artist-container"><h2 className="lyrics-song-artist">Drake🤖</h2></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="lyrics-song-container">
          <div className="lyrics-song">
            <div className="lyrics-song-inner">
              <ul className="lyrics-song-lines">
                {
                  this.props.song.lines
                    .map(line => this.censorLine(line))
                    .map(line => this.renderLine(line))
                }
              </ul>
              <div className="song-share-container">
                <div className="song-share-inner">
                  <div className="song-share">
                    <h3 className="song-share-title">Share this song</h3>
                    <input 
                      onClick={e => this.shareInputOnClick(e)}
                      className="song-share-input"
                      type="text"
                      value={this.songLink(this.props.song)}
                      readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Lyrics;
